import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { usePageContext } from '../context/page-context'
import AnchorLink from './anchor-link'

const Link = ({ to, style, children, state, localized, targetBlank, stripHash, ...props }) => {
  const { lang, originalPath } = usePageContext()
  const referral = originalPath ? originalPath.substring(1) : undefined
  let path = to
  if (!path)
    return (
      <GatsbyLink css={style} to="/" state={{ referral, ...state }} {...props}>
        {children}
      </GatsbyLink>
    )

  const currentHost =
    typeof window !== 'undefined'
      ? `${window.location.protocol}//${window.location.hostname}`
      : null

  if (currentHost && path.indexOf(currentHost) === 0) {
    path = path.replace(currentHost, '')
  }

  if ((path.indexOf('/') !== 0 && path.indexOf('#') !== 0) || (targetBlank))
    return (
      <a href={path} css={style} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    )

  if (path.indexOf('#') > -1) {
    if (path.indexOf('#request-demo') > -1 || path.indexOf('#partnership-enquiry') > -1) {
      return (
        <GatsbyLink
          to={`${originalPath}${path}`}
          css={style}
          state={{ referral, ...state }}
          {...props}
        >
          {children}
        </GatsbyLink>
      )
    }

    if (path.indexOf('#') === 0) path = `${originalPath}${path}`
    return (
      <AnchorLink
        to={localized ? `/${lang}${path}` : `${path}`}
        css={style}
        state={{ referral, ...state }}
        {...props}
      >
        {children}
      </AnchorLink>
    )
  }

  return (
    <GatsbyLink
      to={localized ? `/${lang}${path}` : `${path}`}
      css={style}
      state={{ referral, ...state }}
      {...props}
    >
      {children}
    </GatsbyLink>
  )
}

Link.defaultProps = {
  localized: true,
}

Link.propTypes = {
  to: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
  state: PropTypes.object,
  props: PropTypes.object,
  localized: PropTypes.bool,
  targetBlank: PropTypes.bool,
}

export default Link
